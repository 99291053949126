/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;


$primary-color: #2196F3; // blue-500
$accent-color: #43a047; // amber-A200
$default-input-width: 400px;

$appHeaderColor: #2196F3; //#33a8f4; //#2e85c3
$bodyColor: #f1f4f8;

$allDeptsColor: #866d34;
$groceryColor: #3875ce;
$meatColor: #b93c3c;
$produceColor: #147b0a;
$deliBakeryColor: #d09900;
$dairyColor: #15aaab;
$hbaColor: #6c4894;
$frozenFoodColor: #8ac4f7;
$previewColor: #62af0e;

$assets-color: purple;
$transfer-color: orange;
$delete-color: red;

//@import "~@ag-grid-community/all-modules/dist/styles/ag-grid.css";
//@import "~@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css";
/* Core Data Grid CSS */
@use 'ag-grid-community/styles/ag-grid.css';
/* Quartz Theme Specific CSS */
@use 'ag-grid-community/styles/ag-theme-balham.css';

.ag-theme-balham {
    font: 400 15px "Roboto", "Arial", "Helvetica Neue", sans-serif;
}

.ag-theme-balham .ag-cell {
    line-height: 34px;
    border-left: #eee solid 1px;
}

.ag-theme-balham .ag-header {
    background-color: #fafafa;
}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	// font-size: 100%;
	/*font: inherit;*/
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1.3;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
    border-spacing: 0;
    width:100%;
}

/******end reset*********/

html{
	height:100%;
}

body{
	font-family: 'Roboto', Arial, san-serif;
	font-size:16px;
	margin:0;
	height:100%;
	min-width:1024px;
	background: $bodyColor;
	color:#333;
}

.mat-mdc-progress-spinner circle, .mat-mdc-progress-spinner circle {
    stroke: #ed1c25 !important;
}

.mat-mdc-progress-bar {
  margin-bottom: 8px;
}

#app-header,
#notification-window .title-bar {
    background: $appHeaderColor; 
}

#home-page-header {
    background: linear-gradient(to right, desaturate(rgba($appHeaderColor,.5), 20%) 0%,rgba(125,185,232,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

#home-tiles .tile-wrapper .mat-mdc-card > i {
    color:desaturate(rgba($appHeaderColor, .2), 50%);
}

#offline-link a{
    color: lighten($appHeaderColor, 30%);
}

#login-form input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 100px $bodyColor inset;
    box-shadow: 0 0 0 100px $bodyColor inset;
}

.tile-wrapper .mat-mdc-card{
    box-shadow: 1px 2px 8px rgba(0,0,0,.4) !important;
}

[hidden] {
    display: none !important;
}

.main-content{
	padding:50px 10px 0px;
}

.content {
	position: relative;
    padding: 20px;
}

button{
	text-transform: uppercase;
	cursor: pointer;
}

button i{
	margin-right:10px;
}

button[mat-stroked-button] {
  background: white;
}

.btn {
  cursor: pointer;
}

/* makes drop down menus wider and wrap text */
button.mat-mdc-menu-item {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  cursor: pointer;
  outline: 0;
  border: none;
  -webkit-tap-highlight-color: transparent;
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  line-height: 18px;
  height: auto;
  padding: 10px 16px;
  text-align: left;
  text-decoration: none;
  max-width: 100%;
  position: relative;
}

div.mat-mdc-menu-panel {
  min-width: 112px;
    /** set width to 500px */
  max-width: 500px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  max-height: calc(100vh - 48px);
  border-radius: 4px;
  outline: 0;
}

.mdc-button,
.mat-mdc-raised-button {
  text-transform: uppercase !important;
  letter-spacing: 0 !important;
}
.mat-mdc-raised-button.mat-primary {
  background: $primary-color;
  color: white;
}

// .indenter {
//   display: inline-block;
//   margin: 0 0 0 20px;
//   color: transparent;
// }

// .indenter + .indenter{
//   margin: 0 10px 0 20px;
//   color:#000;
// }

.indent-1{
  display: inline-block;
  margin-left: 20px;
}

.indent-2{
  display: inline-block;
  margin-left: 40px;
}

.indent-3{
  display: inline-block;
  margin-left: 60px;
}

.indent-4{
  display: inline-block;
  margin-left: 80px;
}

.indent-5{
  display: inline-block;
  margin-left: 100px;
}

.indent-6{
  display: inline-block;
  margin-left: 120px;
}

.indent-7{
  display: inline-block;
  margin-left: 140px;
}

.indent-8{
  display: inline-block;
  margin-left: 160px;
}

#ag-grid-wrapper{
	transition: all .5s;
}

.ag-side-buttons button{
    text-transform: unset;
}

.ag-side-buttons button i{
    margin-right:0;
}

.ag-root-wrapper .ag-cell button{
	border:none;
	background: $appHeaderColor;
	color:#fff;
	border-radius: 3px;
	min-width: 30px;
	margin-right: 10px;
	height: 22px;
}

.ag-root-wrapper .ag-cell button:disabled{
	background: #ccc;
}

.ag-cell button:only-child {
  margin: 0;
}

.ag-root-wrapper .ag-floating-filter-button button {
    min-width: unset;
    margin-right: 0;
}

.ag-root-wrapper .ag-cell button i{
	margin-right:0 !important;
}

.ag-cell-focus.action-col{
	border:none !important;
	border-right: 1px solid #e0e0e0 !important;
}

.ag-cell.changed{
	background-color:rgba(95,255,0,.3);
}

.ag-cell.last, .ag-cell.old {
    font-style: italic;
    background: rgba(0,0,0,.03);
}

.ag-cell-inline-editing{
    height: 38px !important;
}

.cell-balloon {
    height: 30px;
    line-height: 30px;
    background: green;
    padding: 0 15px;
    color: white;
    border-radius: 10px;
    font-size: 13px;
}

.cell-balloon:after{
    content: "";
    position: absolute;
    top: 26px;
    left: 10px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid green;
}

.ag-body-viewport {
    // Use momentum-based scrolling on WebKit-based touch devices.
    -webkit-overflow-scrolling: touch;
}

.ag-body-viewport .highlight{
    background-color: #ff0 !important;
    outline: yellow 50px solid !important;
}

#pm-ag-grid-wrapper .old-values-column{
    background: rgba(0, 0, 0, .05);
    font-style: italic;
}

.tar {
    text-align: right;
}

.tac {
    text-align: center;
}

h1{
	font-size: 24px;
	margin-bottom:20px;
	line-height: 130%;
}

#ad-week-grid-and-controls h1{
	margin-bottom:10px;
}

.admin-dialog h1{
  padding: 10px 20px;
  color: white !important;
  margin: 20px !important;
}

.admin-dialog h1:before{
  content: '';
  height: 0;
}

.admin-dialog.detail h1{
  background: $primary-color;
}

.admin-dialog.detail h1.accent{
  background: $accent-color;
}

.admin-dialog.assets h1{
  background: $assets-color;
}

.admin-dialog.transfer h1{
  background: $transfer-color;
}

.admin-dialog.delete h1{
  background: $delete-color;
}

// app-admin-detail-dialog h1,
// app-assets-dialog h1,
// app-transfer-dialog h1
// app-group-headers-dialog h1 {
//     background: #33a8f4;
//     padding: 10px 0;
//     color: white !important;
//     // text-indent: 20px;
//     margin: 0;
// }

// app-admin-detail-dialog h1.accent,
// app-group-headers-dialog h1.accent {
//     background: $accent-color;
// }

// app-assets-dialog h1 {
//   background-color: purple;
// }

// app-transfer-dialog h1 {
//   background-color: orange;
// }

// .mat-mdc-dialog-container rpms-admin-store-group-detail h1,
// .mat-dialog-container rpms-admin-store-detail h1,
// .mat-dialog-container rpms-admin-user-detail h1 
// {
//     background: #33a8f4;
//     // padding: 10px 0;
//     color: white !important;
//     // text-indent: 20px;
//     // margin: 0;
// }

// .mat-mdc-dialog-container rpms-admin-store-group-detail h1.accent,
// .mat-dialog-container rpms-admin-store-detail h1.accent,
// .mat-dialog-container rpms-admin-user-detail h1.accent 
// {
//     background: $accent-color;
// }

h2{
	margin-bottom:15px;
}

h3 {
    font-size: 20px;
    margin-bottom: 10px;
}

h1 .fa,
h1 .far,
h1 .fas {
    margin-right:12px;
}

h1 span {
    font-size: 16px;
    font-weight: normal;
    padding-left: 40px;
    color: #777;
}

a{
    color: $primary-color;
    cursor: pointer;
}

.rel{
	position: relative;
}

.ib {
	display: inline-block;
}

.tac{
	text-align: center;
}

.mb {
    margin-bottom: 20px !important;
}

span.not-active {
    font-style: italic;
    color: #aaa;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
#edit-pane .mat-form-field-wrapper {
    margin: 0 !important;
    padding: 0 !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
.mat-mdc-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: #5ad35f !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
.mat-mdc-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: rgba(0, 0, 0, 0.38) !important;
}

.cf:before,
.cf:after {
    content: " ";
    display: table;
}
.cf:after {
    clear: both;
}

.close {
    position: absolute;
    height: 20px;
    width: 20px;
    top: 50%;
    right: 10px;
    font-size: 14px;
    color: #fff;
    background: rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: all .5s;
    text-align: center;
    line-height: 22px;
    margin-top: -10px;
}

.close:hover {
    background: rgba(0,0,0,.3);
    border-radius: 20px;
}

.notification-wrapper .close {
    top: 17px;
}

.editor-wrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    background: white;
    border: #fff solid 5px;
    box-shadow: 0px 20px 100px rgba(0,0,0,.8);
    border-radius: 8px;
    overflow: hidden;
    z-index: 9;
}

#price-percent-editor-wrapper {
    height: 530px;
    width: 900px;
    margin-top: -260px;
    margin-left: -450px;
}

#book-editor-wrapper {
    height: 575px;
    width: 900px;
    margin-top: -287px;
    margin-left: -450px;
}

#shelf-tag-editor-wrapper {
    height: 500px;
    width: 300px;
    margin-top: -250px;
    margin-left: -150px;
}

#tpr-tag-editor-wrapper {
    height: 560px;
    width: 300px;
    margin-top: -250px;
    margin-left: -150px;
}

#item-list-editor-wrapper {
    height: 500px;
    width: 800px;
    margin-top: -250px;
    margin-left: -400px;
}

#controlled-store-pricing-wrapper {
  width: 500px;
  margin-top: -287px;
  margin-left: -250px;
}

.editor-title {
    font-size: 18px;
    background: $primary-color;
    height: 40px;
    line-height: 40px;
    padding: 0 20px;
    text-transform: uppercase;
    font-weight: bold;
    color: #ffffff;
    cursor: move;
    position: relative;
}

.editor-item-title {
    padding: 0 20px;
    border-bottom: #333 solid 1px;
    height: 50px;
    line-height: 50px;
    font-weight: bold;
    font-size: 18px;
}

.editor-item-title h3 {
    margin: 0;
}

.editor-content{
    position: relative;
}

label.mat-label {
    font-size: 12px;
    color: #888;
    margin-bottom: 10px;
    display: block;
}

.dialog-content{
    margin-bottom:20px;
    line-height: 130%;
    max-height: 50vh;
    overflow: auto;
}

#reports-page .sidebar-nav{
	width: 270px;
}

#admin-page .sidebar-nav{
	width: 215px;
}

#reports-page .content-with-sidebar{
    margin-left:270px;
}

#admin-page .content-with-sidebar{
    margin-left:215px;
}

.content-with-sidebar{
	position: relative;
	padding:20px;
}

.sidebar-nav{
	position:fixed;
	top:40px;
	left:0;
    //background: desaturate(rgba($appHeaderColor, .1), 50%);
    background: #375c74;
    color: #cadaef;
	height:100%;
	overflow-x:hidden;
	overflow-y:auto;
    box-shadow: inset -1px 1px 2px 0px rgba(0,0,0,.2);
    z-index: 9;
}

	.sidebar-nav ul{
		padding:0;
		margin:0 0 50px;
        list-style:none;
    }

	.sidebar-nav ul li{
        padding: 14px 10px;
        border-bottom: rgba(0,0,0,.2) solid 1px;
        cursor: pointer;
		outline:none;
		font-size:15px;
	}

	.sidebar-nav li.active{
        background-color: $bodyColor;
    }
    
    .sidebar-nav li:not(.active):hover{
        background:rgba(0,0,0,.1);
	}

    .sidebar-nav li:first-child.active{
        background-color: $bodyColor;
        box-shadow: inset 2px 2px 2px rgba(0,0,0,.3);
    }
    
    .sidebar-nav li:first-child:not(.active):hover{
        background:rgba(0,0,0,.05);
        box-shadow: inset 2px 2px 2px rgba(0,0,0,.3);
	}

	.sidebar-nav li i{
		position: absolute;
		width:20px;
        text-align:center;
    }

	.sidebar-nav li span{
		display: block;
    	padding: 0 0 0 30px;
    }

    .sidebar-nav li.active span,
    .sidebar-nav li.active i {
        color: #375c74;
    }

.sidebar-nav .menu {
    padding: 10px;
    border-bottom: rgba(0,0,0,.15) solid 1px;
}

    .sidebar-nav .menu span {
        font-size: 20px;
        font-weight: bold;
    }

    .sidebar-nav .menu i {
        float: right;
        cursor: pointer;
        padding-top: 3px;
    }

.cdk-overlay-pane{
	transition: all .5s !important;
}

// .mat-mdc-dialog-content {
//     padding: 10px 24px !important;
// }

.mat-mdc-dialog-title {
    margin: 0 !important;
}

.mat-mdc-dialog-content p{
    margin:20px 0;
    line-height: 140%;
}

.mat-mdc-dialog-content .dialog-error{
    font-size: 14px;
    font-style: italic;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.mat-tab-body-wrapper {
    margin-top: 20px;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.mat-tab-label{
	text-transform: uppercase;
}

/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
mat-radio-group {
  margin: 0px 0 10px;
}

/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
mat-radio-button {
    margin-right: 30px;
}

.report form{
    max-width: none;
    padding-bottom: 0;
}

form button{
	margin-right: 10px !important;
}

.spaced-row{
	margin:20px 0;
}

.row {
    display: flex;
    align-items: baseline;
    margin:10px 0;
}

.row .mat-mdc-form-field {
    flex-grow: 1;
    margin-right: 20px;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.mat-tab-body-content {
    padding: 10px;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
// .mat-mdc-form-field.highlight .mat-form-field-outline{
//     background: #ffffcc !important;
// }

.mat-mdc-form-field.highlight .mdc-text-field{
    background: #ffffcc !important;
}

.checkbox-form-field{
    // margin: 20px 10px 20px 0;
}

.checkbox-form-field label{
	margin-right:40px;
}

// .ag-theme-material {
// 	background-color: #fff;
// 	color: rgba(0, 0, 0, 0.87);
// 	font: 400 15px "Roboto", sans-serif;
// 	box-shadow: 0px 1px 4px rgba(0,0,0,.3);
// }

// .ag-theme-material .ag-cell {
//     line-height: 35px;
//     padding-left: 15px;
//     padding-right: 15px;
// }

.page-heading{
	position: relative;
}

.page-heading:after {
	content: "";
	display: table;
	clear: both;
}

.page-heading h1{
	float:left;
}

.page-heading .page-heading-buttons{
	float:right;
}

.page-heading .page-heading-buttons button{
	margin-left:10px;
}

.table-heading{
	position: relative;
	margin:10px 0;
}

.table-heading button{
	position:absolute !important;
	top:-20px;
	right:0;
}

.table-toolbar{
	border: #bbb solid 1px;
    padding: 10px;
    font-size: 13px;
    //background: desaturate(rgba($appHeaderColor, .3), 50%);
    background: #e6e6e6;
    border-bottom: none;
}

.table-toolbar:after {
	content: "";
	display: table;
	clear: both;
}

.table-toolbar button{
	margin-right:10px;
	font-size: 12px;
    line-height: 24px;
    min-width: 0;
}

.table-toolbar button i{
    margin-right: 0;
}


.table-toolbar button:last-child{
	margin-right:0;
}

.table-toolbar .quick-filter{
    padding: 4px 10px;
    border-radius: 20px;
    border: #ccc solid 1px;
}

.field-note{
    font-size: 12px;
	margin: 20px 0;
}

.filter-tools {
    float: left;
}

.column-tools {
    float: right;
}

.mat-mdc-dialog-container{
	position: relative;
}

.mat-expansion-panel-content {
    font-size: 16px !important;
}

.window-open{
    background:#197c00;
}

.window-closed{
    background: #f00;
}

.window-open, .window-closed{
    margin-left: 20px;
    font-size: 13px;
    color: #fff;
    padding: 5px 18px;
    border-radius: 20px;
    float: right;
}

.tile-wrapper {
    float: left;
    width: 25%;
}

.tile-wrapper .mat-mdc-card{
    margin:10px;
    min-height: 150px;
    position:relative;
    padding:20px;
    text-align: left;
    overflow:hidden;
}

.tile-wrapper button{
    position: absolute;
    bottom:10px;
    right:10px;
    min-width: 100px;
}

.tile-wrapper h2 {
    margin-bottom: 15px;
    padding-bottom: 10px;
    border-bottom: #ddd solid 1px;
    font-size: 20px;
}

.tile-wrapper .mat-mdc-card > i {
    display: inline-block;
    position: absolute;
    top: -5px;
    right: 30px;
    font-size: 120px;
    width: 80px;
    text-align: center;
    color: desaturate(rgba($appHeaderColor, .12), 50%);
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg);
}

.tile-wrapper .mat-mdc-card .card-content-wrapper{
    position: absolute; /* this is so it will appear in front of background icon */
    height:100%;
    width: calc(100% - 40px);    
}

.tile-wrapper .mat-mdc-card .mat-mdc-card-content {
    margin:10px 0;
    font-size: 16px;
}

.tile-wrapper .mat-mdc-card p {
    margin:10px 0;
    line-height: 140%;
}

.independent-store {
	margin: 10px 0;
}

.controlling-store {
	margin: 5px 0 0;
	font-weight: bold;
}

.controlled-store {
	margin: 10px 0;
}

	.controlled-store span{
		color:#ccc;
	}

.controlled-store-wrapper {
	border-left: #ccc solid 1px;
	margin-left: 15px;
	margin-bottom: 20px;
}

.report-page-buttons{
    padding: 10px;
    border: #bbb solid 1px;
    // background: desaturate(rgba($appHeaderColor, .1), 50%);
    background: #d1d1d1;
    border-bottom: none;
    border-radius: 5px 5px 0 0;
    font-size: 14px;
}

.report-page-buttons button {
    height: 28px;
    line-height: 30px;
}

.report-page-buttons .show-all-rows{
    display: inline-block;
    margin-left: 10px;
    // position: relative;
    // top: 5px;
}

.report-page-buttons .export-btn {
    float: right;
}

.report-page-buttons .rendering{
    display:none;
}

.report-criteria{
    position: relative;
}

.report-description {
    margin: 20px 0;
    border-top: #ccc solid 1px;
    border-bottom: #ccc solid 1px;
    padding: 10px 0;
}

.update-button {
    position: absolute;
    top: 50%;
    left: 50%;
}

.warning-text{
    color: #f00;
    position: absolute;
    top: 0;
    font-size: 13px;
    right: 0;
}

.report-date{
    float:right;
    font-weight: bold;
    font-size: 20px;
}

.row-count {
    font-weight: bold;
    font-size: 13px;
    margin-top: 10px;
}

a.button {
  padding: 8px 20px;
  background: $primary-color;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  display: inline-block;
}

a.button:hover {
  background: darken($primary-color, 10%);
}

#help-page a.button {
  margin: 20px 10px 20px 0;
}

#toast-holder {
    position: fixed;
    height: 100%;
    top: 0;
    left: 0;
    width: 100%;
    pointer-events: none;
    margin: 0;
    padding: 0;
    z-index: 99999;
}

#toasts-top-right {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 9999;
}

    #toasts-top-right span {
        display: inline-block;
        padding: 0px 20px;
        margin-top: 10px;
        border-radius: 5px;
        border: #fff solid 1px;
        box-shadow: 2px 2px 5px rgba(0,0,0,.3);
        background: #333;
        color: #fff !important;
        font-size: 13px;
        line-height: 30px;
        height: 30px;
        margin-right:15px;
    }

    #toasts-top-right span.error {
      background: red;
    }

    #toasts-top-right div {
        width: 100%;
        margin-top: -42px;
        animation: slideUp 5s forwards;
        /*animation-delay: 2s;*/
        text-align: right;
    }

    @keyframes slideUp {
        0% {
            opacity: 1;
            margin-top: -42px;
        }
        10% {
            opacity: 1;
            margin-top: 0;
        }
        90% {
            opacity: 1;
            margin-top: 0;
        }
        100% {
            opacity: 0;
            margin-top: -42px;
        }
    }

#toasts-bottom-left {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 9999;
    overflow:hidden;
}

    #toasts-bottom-left span {
        display: inline-block;
        padding: 0px 20px;
        margin-bottom: 10px;
        border-radius: 5px;
        border: #fff solid 1px;
        box-shadow: 2px 2px 5px rgba(0,0,0,.3);
        background: #333;
        color: #fff !important;
        font-size: 13px;
        line-height: 30px;
        height: 30px;
        margin-left: 15px;
    }

    #toasts-bottom-left span.success {
        background: #135c00;
    }

    #toasts-bottom-left span.alert {
        background: #f00;
    }

    #toasts-bottom-left div {
        width: 100%;
        margin-bottom: -42px;
        animation: slideDown 5s forwards;
        /*animation-delay: 2s;*/
        text-align: left;
    }

@keyframes slideDown {
    0% {
        opacity: 1;
        margin-bottom: -42px;
    }

    10% {
        opacity: 1;
        margin-bottom: 0;
    }

    90% {
        opacity: 1;
        margin-bottom: 0;
    }

    100% {
        opacity: 0;
        margin-bottom: -42px;
    }
}

.mat-mdc-select-panel {
  max-height: 500px;
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
.mat-select-override {
    display: flex;
    align-items: center;
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
#ad-week-top-controls .mat-select-override{
    margin-right: 20px;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-select-override .mat-mdc-form-field,
.mat-select-override .mat-form-field-wrapper,
.mat-select-override .mat-form-field-infix
{
    padding:0 !important;
    margin:0 !important;
    border:0 !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
#ad-week-top-controls .mat-select-override .mat-mdc-form-field {
    width: 200px !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-select-override .mat-form-field-underline{
    display:none !important;
}



/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
.mat-select-override .mat-select-value-text,
.mat-select-override .mat-select-placeholder {
    padding-left: 5px;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-select-override .mat-form-field-type-mat-select .mat-form-field-label {
    padding-left: 10px;
    top: 17px;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-select-override .mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label, 
.mat-select-override .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    -webkit-transform: translateY(0) scale(1);
    transform: translateY(0) scale(1);
    opacity: 0;
}

.mat-mdc-select-panel{
    position: relative !important;
    top: 2px !important;
    max-height: 500px !important;
}

// .mat-select-panel .mat-optgroup-label, 
// .mat-select-panel .mat-option {
//     //line-height: 2em !important;
//     //height: 2em !important;
// }

#AllItems-tab,
#AllItems-subtab-container {
    background: $allDeptsColor;
}

#Grocery-tab,
#Grocery-subtab-container {
    background: $groceryColor;
}

#Meat-tab,
#Meat-subtab-container {
    background: $meatColor;
}

#Produce-tab,
#Produce-subtab-container {
    background: $produceColor;
}

#DeliBakery-tab,
#DeliBakery-subtab-container {
    background: $deliBakeryColor;
}

#Dairy-tab,
#Dairy-subtab-container {
    background: $dairyColor;
}

#HBA-tab,
#HBA-subtab-container {
    background: $hbaColor;
}

#FrozenFood-tab,
#FrozenFood-subtab-container {
    background: $frozenFoodColor;
}

#Preview-tab,
#Preview-subtab-container {
    background: $previewColor;
}

#pm-layout {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px);
}

#pm-grid-and-footer-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  border: #ccc solid 6px;
  border-top: none;
}

// #pm-ag-grid-wrapper {
//     box-sizing: border-box;
//     border: $allDeptsColor solid 6px;
//     border-top: none;
// }

#pm-grid-footer-wrapper {
    // border: $allDeptsColor solid 6px;
    background: $allDeptsColor;
}

#pm-statuses-wrapper {
  height: 35px;
}

#grid-footer-up, #grid-footer-down {
    display: inline-block;
    padding: 0 10px;
    height: 20px;
    line-height: 20px;
    position: absolute;
    border-radius: 3px 3px 0 0;
    background: #777;
    margin: 0;
    left:0;
    cursor: pointer;
    border: none;
    color: #fff;
    opacity: 0.5;
    bottom: 0;
}

#offline-price-management-page #grid-footer-up, 
#offline-price-management-page #grid-footer-down {
    background-color: #777;
}

// #grid-footer-up{
// 	top:-22px;
//   bottom: unset;
// }

// #grid-footer-down{
//     bottom:0;
//     top:unset;
// }

#grid-footer-up:hover,
#grid-footer-down:hover
{
    opacity: 1;
}

// #pm-grid-and-controls.AllItems #pm-ag-grid-wrapper { border-color: $allDeptsColor; }
// #pm-grid-and-controls.Grocery #pm-ag-grid-wrapper { border-color: $groceryColor; }
// #pm-grid-and-controls.Meat #pm-ag-grid-wrapper { border-color: $meatColor; }
// #pm-grid-and-controls.Produce #pm-ag-grid-wrapper { border-color: $produceColor; }
// #pm-grid-and-controls.DeliBakery #pm-ag-grid-wrapper { border-color: $deliBakeryColor; }
// #pm-grid-and-controls.Dairy #pm-ag-grid-wrapper { border-color: $dairyColor; }
// #pm-grid-and-controls.HBA #pm-ag-grid-wrapper { border-color: $hbaColor; }
// #pm-grid-and-controls.FrozenFood #pm-ag-grid-wrapper { border-color: $frozenFoodColor; }
// #pm-grid-and-controls.Preview #pm-ag-grid-wrapper { border-color: $previewColor; }

#pm-grid-and-controls.AllItems #pm-grid-and-footer-wrapper { border-color: $allDeptsColor; }
#pm-grid-and-controls.Grocery #pm-grid-and-footer-wrapper { border-color: $groceryColor; }
#pm-grid-and-controls.Meat #pm-grid-and-footer-wrapper { border-color: $meatColor; }
#pm-grid-and-controls.Produce #pm-grid-and-footer-wrapper { border-color: $produceColor; }
#pm-grid-and-controls.DeliBakery #pm-grid-and-footer-wrapper { border-color: $deliBakeryColor; }
#pm-grid-and-controls.Dairy #pm-grid-and-footer-wrapper { border-color: $dairyColor; }
#pm-grid-and-controls.HBA #pm-grid-and-footer-wrapper { border-color: $hbaColor; }
#pm-grid-and-controls.FrozenFood #pm-grid-and-footer-wrapper { border-color: $frozenFoodColor; }
#pm-grid-and-controls.Preview #pm-grid-and-footer-wrapper { border-color: $previewColor; }

#pm-grid-and-controls.AllItems #pm-grid-footer-wrapper { border-color: $allDeptsColor; background: $allDeptsColor}
#pm-grid-and-controls.Grocery #pm-grid-footer-wrapper { border-color: $groceryColor; background: $groceryColor }
#pm-grid-and-controls.Meat #pm-grid-footer-wrapper { border-color: $meatColor; background: $meatColor }
#pm-grid-and-controls.Produce #pm-grid-footer-wrapper { border-color: $produceColor; background: $produceColor }
#pm-grid-and-controls.DeliBakery #pm-grid-footer-wrapper { border-color: $deliBakeryColor; background: $deliBakeryColor }
#pm-grid-and-controls.Dairy #pm-grid-footer-wrapper { border-color: $dairyColor; background: $dairyColor }
#pm-grid-and-controls.HBA #pm-grid-footer-wrapper { border-color: $hbaColor; background: $hbaColor }
#pm-grid-and-controls.FrozenFood #pm-grid-footer-wrapper { border-color: $frozenFoodColor; background: $frozenFoodColor }
#pm-grid-and-controls.Preview #pm-grid-footer-wrapper { border-color: $previewColor; background: $previewColor }

#pm-grid-and-controls.AllItems .ag-floating-bottom { border-color: $allDeptsColor; background: $allDeptsColor}
#pm-grid-and-controls.Grocery .ag-floating-bottom { border-color: $groceryColor; background: $groceryColor }
#pm-grid-and-controls.Meat .ag-floating-bottom { border-color: $meatColor; background: $meatColor }
#pm-grid-and-controls.Produce .ag-floating-bottom { border-color: $produceColor; background: $produceColor }
#pm-grid-and-controls.DeliBakery .ag-floating-bottom { border-color: $deliBakeryColor; background: $deliBakeryColor }
#pm-grid-and-controls.Dairy .ag-floating-bottom { border-color: $dairyColor; background: $dairyColor }
#pm-grid-and-controls.HBA .ag-floating-bottom { border-color: $hbaColor; background: $hbaColor }
#pm-grid-and-controls.FrozenFood .ag-floating-bottom { border-color: $frozenFoodColor; background: $frozenFoodColor }
#pm-grid-and-controls.Preview .ag-floating-bottom { border-color: $previewColor; background: $previewColor }

#pm-grid-and-controls.AllItems .ag-root { border-color: $allDeptsColor; }
#pm-grid-and-controls.Grocery .ag-root { border-color: $groceryColor; }
#pm-grid-and-controls.Meat .ag-root { border-color: $meatColor; }
#pm-grid-and-controls.Produce .ag-root { border-color: $produceColor; }
#pm-grid-and-controls.DeliBakery .ag-root { border-color: $deliBakeryColor; }
#pm-grid-and-controls.Dairy .ag-root { border-color: $dairyColor;  }
#pm-grid-and-controls.HBA .ag-root { border-color: $hbaColor; }
#pm-grid-and-controls.FrozenFood .ag-root { border-color: $frozenFoodColor; }
#pm-grid-and-controls.Preview .ag-root { border-color: $previewColor; }

#pm-grid-and-controls.AllItems .toggle-footer { background: $allDeptsColor}
#pm-grid-and-controls.Grocery .toggle-footer { background: $groceryColor }
#pm-grid-and-controls.Meat .toggle-footer { background: $meatColor }
#pm-grid-and-controls.Produce .toggle-footer { background: $produceColor }
#pm-grid-and-controls.DeliBakery .toggle-footer { background: $deliBakeryColor }
#pm-grid-and-controls.Dairy .toggle-footer { background: $dairyColor }
#pm-grid-and-controls.HBA .toggle-footer { background: $hbaColor }
#pm-grid-and-controls.FrozenFood .toggle-footer { background: $frozenFoodColor }
#pm-grid-and-controls.Preview .toggle-footer { background: $previewColor }

#pm-ag-grid-wrapper .ag-floating-bottom {
    border-top-style: solid;
    border-top-width: 2px;
}

#pm-top-controls,
#ad-week-top-controls{
    position:relative;
    margin-bottom:10px;
}

#pm-filter-tools {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
}

#pm-filter-tools *{
    font-size: 14px;
}

#pm-filter-tools button:not(.mat-mdc-raised-button){
    background: #e1f3ff;
    border: #aaa solid 1px;
    height: 26px;
    line-height: 26px;
    margin-left: 5px;
    min-width: 0;
}

#pm-filter-tools button.no-text i {
    margin: 0;
}

#pm-quick-filter{
    line-height: 24px;
    margin-left: 5px;
    border: #aaa solid 1px;
    padding: 0 10px;
    font-size: 15px;
    border-radius: 5px;
    height: 24px;
    width: 100px;
}

#pm-quick-filter::placeholder{
    color:#ccc;
}

#pm-top-controls #export-button,
#ad-week-top-controls #export-button {
    position: absolute;
    right: 0;
    bottom: -35px;
    height: 28px;
    line-height: 26px;
}

#pm-grid-and-controls {
    padding-left: 0px;
    padding-right: 0px;
    position: relative;
    overflow: hidden;
    flex-grow: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
}

#grid-controls {
    position: absolute;
    top: 71px;
    right: 6px;
    font-size: 13px;
    height: 32px;
    padding: 5px 10px;
    background: rgba(255,255,255,.8);
    border-radius: 10px 10px 0 0;
}

#grid-controls button {
    height: 22px;
    line-height: 22px;
    margin-left: 10px;
    font-size: 12px;
    //padding: 0 10px;
    min-width: 0;
}

#grid-controls button i{
    position: relative;
    top: 1px;
    margin-right: 0;
}

#grid-controls #scroll-to-selected-button {
    margin: 0;
}

#grid-footer button {
  height: 22px;
  line-height: 22px;
  margin-right: 10px;
  font-size: 13px;
  background: rgba(255, 255, 255, 0.7);
  min-width: 115px;
  padding: 0 !important;
  margin-bottom: 2px;
}


// #grid-controls .mat-mdc-slide-toggle {
//     position: relative;
//     top: 3px;
//     height: 18px;
//     line-height: 18px;
// }

#grid-controls .divider {
    display: inline-block;
    height: 38px;
    border-left: rgba(0, 0, 0, 0.1) solid 2px;
    position: relative;
    top: -10px;
    margin: 0 8px 0 12px;
}

#pm-ag-grid-wrapper {
    box-sizing: border-box;
    // border: #ccc solid 5px;
    border-top: none;
    flex-grow: 1;
}

.grid-tabs a {
	padding: 5px 10px;
	display: inline-block;
	background: #ccc;
	color: rgba(255,255,255,.9);
	border-radius: 8px 8px 0 0;
	border:none;
    cursor: pointer;
    margin-right:2px;
    font-size: 13px;
    text-transform: uppercase;
    line-height: 20px;
    font-weight: bold;
}

	.grid-tabs a:hover{
		text-decoration:none;
	}
	
.subtab-container {
    height: 36px;
    padding-left: 6px;
    border-radius: 0 14px 0 0;
}

.subtab-container a {
    height: 28px;
    margin-right: 3px;
    display: inline-block;
    background: rgba(255,255,255,.7);
    line-height: 28px;
    padding: 0 10px;
    margin-top: 8px;
    border-radius: 8px 8px 0 0;
    font-size: 13px;
    cursor: pointer;
    color: #333;
}

.subtab-container a i{
    margin-right: 5px;
    font-size: 12px;
}

.subtab-container a.active{
    color:#000;
    background: rgb(250, 250, 250);
}

/* footer */

#pm-grid-footer-wrapper {
  position: relative;
  // bottom: 10px;
  padding: 0;
  box-sizing: border-box;
  // height: 150px;
  border-top: none;
  width: 100%;
  font-size: 15px;
}

#grid-footer {
  background: rgba(255,255,255,.8);
  position: relative;
}

#footer-content{
  height:145px;
  line-height: 22px;
}

#offline-price-management-page #footer-content{
  background-color: rgba(108, 98, 67, 0.0980392);
}

#footer-content .col {
  box-sizing:border-box;
  border-left:#777 solid 1px;
  padding: 2px 8px;
  height: 145px;
}

#footer-content .col:first-child {
  border-left:none;
}

  #footer-content .col div.tag {
      height: auto;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
  }

#footer-content .col div.mvt {
  height: 35px;
  line-height: 120%;
  border-bottom:#777 solid 1px;
  padding: 4px 0;
  text-align: center;
  margin-bottom:5px;
}

#footer-content .col .footer-item-info {
height: auto;
}

#footer-content .col .footer-item-info div {
height: 17px;
}

.footer-header{
  font-weight: bold;
  text-transform: uppercase;
  border-bottom: #777 solid 1px;
  text-align: center;
  line-height: 20px;
  font-size:15px;
  margin:0;
}

.footer-value{
  font-weight:bold;
}

.footer-label{
  display:inline-block;
  width:80px;
}

.mvt .footer-label{
  font-style:italic;
  display:inline;
}

#footer-content a{
  cursor:pointer;
}

#data-information {
  background: #f00;
  padding: 10px 15px;
  font-weight: normal;
  position: relative;
  transition: all 1s;
  border-radius: 5px;
  margin: 0 0 10px;
  color: #fff;
  font-size: 14px;
}

#data-information.synched {
  background: #179b19;
}

#data-information span {
  padding-right: 20px;
}

#data-information button {
  cursor: pointer;
  margin: 0;
  position: absolute;
  right: 38px;
  top: 5px;
  height: 24px;
  line-height: 25px;
  background: rgba(255, 255, 255, 0.6);
  color: #333;
  text-transform: capitalize;
  border: none;
  padding: 0 10px 0 7px;
}

#data-information button span {
  padding-right: 0;
}

.ag-theme-balham .ag-floating-bottom .ag-row {
    background-color: rgba(255,255,255,.8);
}

.ag-floating-bottom .masterChangeCheckbox-col input {
  display: none;
}

.ag-row-group {
    background-color: #ffebbe !important;
    font-size: 18px;
    line-height: 34px;
}

.group-button {
    background-color: #ffebbe !important;
}

#ad-week-grid-wrapper {
    border: $allDeptsColor solid 5px;
    border-top-width: 15px;
    border-radius: 0 10px 0 0;
}

#ad-week-grid-wrapper.AllItems { border-color: $allDeptsColor; }
#ad-week-grid-wrapper.Grocery { border-color: $groceryColor; }
#ad-week-grid-wrapper.Meat { border-color: $meatColor; }
#ad-week-grid-wrapper.Produce { border-color: $produceColor; }
#ad-week-grid-wrapper.DeliBakery { border-color: $deliBakeryColor; }
#ad-week-grid-wrapper.Dairy { border-color: $dairyColor; }
#ad-week-grid-wrapper.HBA { border-color: $hbaColor; }
#ad-week-grid-wrapper.FrozenFood { border-color: $frozenFoodColor; }
#ad-week-grid-wrapper.Preview { border-color: $previewColor; }

.ag-header-cell{
    overflow: hidden;
}

.customHeaderMenuButton {
  position: absolute;
  right: 5px;
  top: 3px;
}

.customHeaderLabel {
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 24px;
    margin-bottom: 5px;
    width: calc(100% - 5px);
}

span.sortIcon {
  display: inline-block;
}

.master-input {
    line-height: 20px;
}

.master-input input:not([type='checkbox']) {
    width: calc(100% + 2px);
    height: 28px;
    padding: 0;
    line-height: 28px;
    text-align: right;
    background: #f1ffd8;
    border: #a9a9a9 solid 1px;
}

.master-input select {
    width: calc(100% + 5px);
    height: 28px;
    padding: 0;
    line-height: 28px;
    background: #f1ffd8;
}

.masterChangeCheckbox-col input[type="checkbox"],
.master-input input[type="checkbox"]
{
    height: 20px;
    width: 20px;
}

//in final confirmation dialog
.master-items-quantity {
    font-size: 120%;
    font-weight: bold;
    color: red;
    display: inline-block;
    padding: 0 5px;
}

span.ag-header-group-text {
    line-height: 24px;
}

.ag-theme-balham .ag-header-cell, 
.ag-theme-balham .ag-header-group-cell {
    padding-left: 8px;
}

.ag-theme-balham .ag-header-cell {
  align-items: flex-start;
}

.ag-theme-balham .ag-floating-filter-button {
  display: flex;
  flex: none;
  align-items: center;
  height: 100%;
}

rpms-custom-header.ng-star-inserted {
  width: 100%;
}

.ag-filter select {
  width: 100%;
  padding: 5px;
  outline: none;
  font-size: 14px;
}

.ag-filter input {
  width: calc(100% - 14px);
  padding: 5px;
  font-size: 14px;
  margin: 2px 0;
}

// .ag-theme-balham .ag-floating-filter-body {
//     // width: calc(100% - 10px);
// }

// .ag-floating-filter-body input {
//     // height: 25px;
//     // margin: 0;
//     // width: 100%;
// }

.ag-row.deleted-item-row {
    background: url('/assets/images/diagonal-stripe-delete.png') 0 0;
}

    // .ag-row.deleted-item-row *,
    // .ag-row.deleted-item-row .ag-cell,
    // .ag-row.deleted-item-row .ag-cell * {
    //     text-decoration: line-through !important;
    // }

#pm-ag-grid-wrapper.ag-theme-balham .ag-ltr .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected), 
#pm-ag-grid-wrapper.ag-theme-balham .ag-ltr .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected), 
#pm-ag-grid-wrapper.ag-theme-balham .ag-ltr .ag-has-focus .ag-full-width-row.ag-row-focus .ag-cell-wrapper.ag-row-group, 
#pm-ag-grid-wrapper.ag-theme-balham .ag-ltr .ag-cell-range-single-cell, 
#pm-ag-grid-wrapper.ag-theme-balham .ag-ltr .ag-cell-range-single-cell.ag-cell-range-handle, 
#pm-ag-grid-wrapper.ag-theme-balham .ag-rtl .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected), 
#pm-ag-grid-wrapper.ag-theme-balham .ag-rtl .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected), 
#pm-ag-grid-wrapper.ag-theme-balham .ag-rtl .ag-has-focus .ag-full-width-row.ag-row-focus .ag-cell-wrapper.ag-row-group, 
#pm-ag-grid-wrapper.ag-theme-balham .ag-rtl .ag-cell-range-single-cell, 
#pm-ag-grid-wrapper.ag-theme-balham .ag-rtl .ag-cell-range-single-cell.ag-cell-range-handle {
  border: 3px solid;
  border-color: #0091ea;
}

#bookCell,
#srpCodeCell,
#multiCell,
#priceCell,
#percentCell{
    height:100%;
    width:100%;
    background-color:transparent;
    border:#777 solid 1px;
    text-align: right;
}

#keepSrpCell,
#keepPercentCell,
#setPriceKeepPercentCell{
    height:20px;
    width:20px;
    position: relative;
    top: 5px;
}

#clear-filters-button.active{
    background: rgba(255,0,0,.3) !important;
}

.change-render i {
  font-size: 12px;
  position: relative;
  top: -1px;
  left: 3px;
}

.price-change-render {
  color: green;
}

.cost-change-render {
  color: red;
}

.wic-change-render {
  color: purple;
}

.upc-change-render {
  color: dodgerblue;
}

.size-change-render {
  color: darkorange;
}

.description-change-render {
  color: brown;
}

div.col-20{
    float:left;
    width:20%;
}

div.col-25{
    float:left;
    width:25%;
}

div.col-33{
    float:left;
    width:33%;
}

div.col-40{
    float:left;
    width:40%;
}

div.col-50{
    float:left;
    width:50%;
}

div.col-66{
    float:left;
    width:66%;
}



.help {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    background: #fff;
    border: #999 solid 1px;
    border-radius: 0 10px 10px 10px;
    transition: all .3s;
    transform: scale(0.1, 0.1);
    transform-origin: top left;
    box-shadow: 2px 2px 2px rgba(0,0,0,.3);
    overflow: hidden;
    z-index: 999;
}

    .help .wrapper {
        max-width: 300px;
        padding: 20px;
        font-size: 14px;
        line-height: 130%;
        max-height: 400px;
        overflow-y: auto;
        overflow-x: hidden;
    }

.help p {
    margin: 10px 0;
}

.help h4 {
    font-size: 18px;
    margin-bottom:10px;
}

.help h5 {
    font-size: 16px;
}

/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
.helpful-mode .help-trigger,
.helpful-mode .help-trigger .mat-slide-toggle-label,
.helpful-mode .help-trigger * {
    cursor: help !important;
}

.breadcrumbs{
	margin: 0 0 20px;
	font-size:12px;
}

	.breadcrumbs span:after{
		content:"\00bb";
		padding:0 7px;
		position:relative;
		top:-2px;
	}
	
	.breadcrumbs span:last-child:after{
		content:"";
  }

/* admin dialogs */

// .admin-dialog .transfer-header {
//   background: orange;
//   color: white;
//   // padding: 10px;
//   // margin-bottom: 10px;
// }

.admin-dialog .vertical-radio {
  display: flex;
  flex-direction: column;
  margin: 0 0 25px;
  align-items: flex-start;
}

.admin-dialog .vertical-radio mat-radio-button {
  margin: 10px;
}

.admin-dialog .badge,
.detail.badge {
  background: #64c119;
  padding: 6px 8px 4px;
  border-radius: 50px;
  margin-left: 10px;
  color: white;
  display: inline-block;
}

.admin-dialog .log-scroller {
  height: calc(100% - 100px);
  min-height: 200px;
  max-height: 400px;
  border: #ccc solid 1px;
  margin: 10px 0;
  overflow: scroll;
  padding: 10px;
}

.admin-dialog .col-headers {
  font-weight: bold;
  text-decoration: underline;
}

.admin-dialog .log-entry {
  display: flex;
  font-size: 80%;
  margin-bottom: 5px;
  padding: 5px;
  line-height: 120%;
}

.admin-dialog .log-entry > div { 
  width: 20%;
  margin-right: 10px;
}

.admin-dialog .log-entry:nth-child(even) { 
  background: #eee;
}

.admin-dialog .log-entry span.description-highlight,
.deletion-history .log-entry span.description-highlight {
  font-weight: bold;
  margin-left: 20px;
}

.admin-dialog .log-entry > div.time { 
  width: 100px;
}

.admin-dialog .log-entry > div.type { 
  width: 100px;
}

.admin-dialog .log-entry > div.entity { 
  width: 120px;
}

.admin-dialog .log-entry > div.description { 
  width: 50%;
}

.admin-dialog .action-buttons {
  margin-bottom: 10px;
}

// .admin-dialog .buttons-wrapper,
// .detail-buttons-wrapper {
//   margin: 20px 0;
// }

.admin-dialog .transfer-button {
  float: right;
}

// .admin-dialog .mat-content {
//   display: flex;
//   flex: unset !important;
//   flex-direction: row;
//   overflow: hidden;
//   justify-content: unset;
// }

.no-assets {
  color: red;
  font-weight: bold;
}

.no-privilege {
  color: red;
  font-weight: bold;
  margin-bottom: 20px;
}
  
/* deletion history dialogs */

// .deletion-history .deletion-history-header {
//   background: red;
//   color: white;
//   padding: 10px;
//   margin-bottom: 10px;
//}
    
// .deletion-history .log-scroller {
//   height: calc(100% - 800px);
//   min-height: 200px;
//   max-height: 400px;
//   border: #ccc solid 1px;
//   margin: 10px 0;
//   overflow: scroll;
//   padding: 0;
// }

.deletion-history .col-headers {
  font-weight: bold;
  text-decoration: underline;
}

.deletion-history .log-entry {
  display: flex;
  font-size: 80%;
  margin-bottom: 5px;
  padding: 5px;
  line-height: 120%;
}

.deletion-history .log-entry > div { 
  width: 20%;
  margin-right: 10px;
}

.deletion-history .log-entry > div.time { 
  width: 100px;
}

.deletion-history .log-entry > div.type { 
  width: 100px;
}

.deletion-history .log-entry > div.entity { 
  width: 120px;
}

.deletion-history .log-entry > div.description { 
  width: 50%;
}

.deletion-history .log-entry:nth-child(even) { 
  background: #eee;
}

.deletion-history .action-buttons {
  margin-bottom: 10px;
}

// .deletion-history .buttons-wrapper,
// .detail-buttons-wrapper {
//   margin: 20px 0;
// }
    
.deletion-history .mat-content {
  display: flex;
  flex: unset !important;
  flex-direction: row;
  overflow: hidden;
  justify-content: unset;
}



/* Owned Assets (component) */

.assets-scroller {
  max-height: 45vh;
  overflow-y: auto;
  border-top: #eee solid 1px;
  border-bottom: #eee solid 1px;
  background: #eee;
  padding: 20px;
}

.assets-scroller .mat-accordion > .mat-expansion-panel-spacing {
  margin: 5px;
  box-shadow: 0 1px 6px rgb(0 0 0 / 30%);
}

.assets-scroller .mat-expansion-panel-header-title {
  font-size: 18px;
  font-weight: bold;
  width: 225px;
  line-height: 120%;
}

.assets-scroller .mat-expansion-panel-header {
  justify-content: space-between;
}

// .assets-scroller .mat-expansion-panel-header-title, 
// .mat-expansion-panel-header-description {
//   display: flex;
//   flex-grow: unset !important;
//   margin-right: 16px;
//   align-items: center;
// }

.mat-accordion>.mat-expansion-panel-spacing:last-child, 
.mat-accordion>*:last-child:not(.mat-expansion-panel) 
.mat-expansion-panel-spacing {
  margin-bottom: 10px !important;
}

.asset-row {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  justify-content: space-between;
}

.asset-row.header {
  font-weight: bold;
  font-size: 12px;
  border-bottom: #ccc solid 1px;
  padding-bottom: 5px;
  // padding-top: 20px;
}

.asset-row > div {
  width: 30%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.asset-row .delete-col {
  width: 20%;
  text-align: right;
}

.asset-row .delete-col button {
  text-transform: unset;
}

.asset-row .delete-col button.mat-mdc-menu-item {
  text-transform: unset;
}

.asset-row .mat-mdc-unelevated-button.mat-warn {
  line-height: 20px;
  background-color: #f44336;
  min-width: 10px;
  padding: 0 10px;
  height: 20px;
}

.asset-row .mat-mdc-unelevated-button.mat-warn i{
  margin: 0;
}

.asset-row .priority-col {
  max-width: 70px;
}

.asset-row .storeNumber-col {
  max-width: 100px;
}

.asset-row .ruleType-col {
  max-width: 120px;
}

  // @media only screen and (max-width: 1025px) {
  //   #shelf-tag-status,
  //   #tpr-tag-status {
  //     max-width: 120px;
  //     display: inline-block;
  //     text-overflow: ellipsis;
  //     white-space: nowrap;
  //     overflow: hidden;
  // }
  // }
//GAIR-133
//FIXES FOR ANGULAR MATERIAL V15

// .detail.admin-dialog h1 {
//   background: $primary-color;
//   color: white;
// }

.mat-mdc-tab-labels {
  border-bottom: #ccc solid 1px;
}

.mdc-tab__text-label {
  text-transform: uppercase;
}

.detail-action-buttons {
  padding: 20px 0;
}

.mat-mdc-checkbox label {
  cursor: pointer;
  margin-right: 30px;
}

.mdc-switch__track {
  overflow: hidden;
  position: relative;
  width: 100%;
  border: #555 solid 1px;
}

#app-header label#mat-mdc-slide-toggle-1-label {
  color: white;
}

.mat-mdc-select-override .mat-select,
#pm-store-select,
#adweek-store-select,
#adweek-week-select{
  height: 24px;
  line-height: 24px;
  border: #aaa solid 1px;
  border-radius: 5px;
  font-family: Roboto;
  margin-left: 5px;
  background: #fff;
  width: 280px;
  padding: 0 10px;
}

#item-filter-select{
  height: 24px;
  line-height: 24px;
  border: #aaa solid 1px;
  border-radius: 5px;
  font-family: Roboto;
  margin-left: 5px;
  background: #fff;
  width: 280px;
  padding: 0 10px;
}

#grid-footer #keepSrpCb .mdc-form-field,
#grid-footer #keepPercentCb .mdc-form-field {
  height: 20px;
}

#shelf-tag-editor-wrapper {
  height: 80%;
  width: 300px;
  margin: 0;
  translate: -50% -50%;
  max-height: 620px;
}

#tpr-tag-editor-wrapper {
  height: 80%;
  width: 300px;
  margin: 0;
  translate: -50% -50%;
  max-height: 560px;
}

mat-card-footer.mat-mdc-card-footer {
  position: absolute;
  bottom: 20px;
  right: 20px;
  display: flex;
}

mat-card-footer.mat-mdc-card-footer.right-justified {
  justify-content: flex-end;
}

.tile-wrapper button {
  position: static;
  bottom: unset;
  right: unset;
  min-width: 100px;
}

.tile-wrapper .mat-mdc-card .card-content-wrapper {
  position: static;
  height: unset;
  width: unset;
  z-index: 1;
}

.non-scrolling-detail-action-buttons {
  padding-left: 20px;
  margin-bottom: 20px;
}

.assets.admin-dialog .mat-mdc-dialog-content {
  padding: 0 20px 20px;
}

app-message-dialog h1,
app-confirmation-dialog h1,
app-critical-error dialog h1
{
  padding: 20px;
  margin: 0;
}

app-message-dialog .dialog-content,
app-critical-error dialog .dialog-content,
app-confirmation-dialog .dialog-content
{
  padding: 20px;
  margin: 0;
}

app-message-dialog .dialog-actions,
app-critical-error dialog .dialog-actions,
app-confirmation-dialog .dialog-actions
{
  margin: 20px;
}

.mdc-tab__text-label {
  letter-spacing: 0;
}

.admin-dialog .buttons-wrapper {
  margin:20px 0;
}

.admin-dialog mat-dialog-actions.buttons-wrapper {
  margin:0;
  padding: 20px;
  border-top: #ccc solid 1px;
}

.ag-header-cell:not(.ag-header-cell-auto-height) .ag-header-cell-comp-wrapper {
  align-items: start;
}

app-critical-error-dialog .dialog-actions {
  padding: 20px;
}

app-critical-error-dialog .dialog-content {
  padding: 20px;
}

app-critical-error-dialog .dialog-title {
  padding: 20px;
  margin: 0;
}

#price-percent-editor-wrapper .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}

#price-percent-editor-wrapper .mat-mdc-form-field-infix {
  min-height: unset;
}

    /******** Print *******/
    @media print {

        html, body {
            background: #fff;
            font-size: 13px;
            line-height: 130%;
        }

        #app-header {
            display: none;
        }

        .main-content {
            padding: 0;
        }

        .sidebar-nav{
            display: none;
        }

        #reports-page .content-with-sidebar {
            margin-left: 0 !important;
            padding: 0;
        }

        .report button,
        .report .mat-mdc-slide-toggle,
        .report .column-tools,
        .report-page-buttons{
            display: none;
        }

        rpms-notification-window {
            display:none;
        }

    }

